import { SSRPass } from './SSRPass.js';

class Reflections {

    constructor() {

    }

    addReflections(scene, camera, renderer, reflectionDist, opacity) {
        var ssrComposer = new THREE.EffectComposer(renderer);
        var ssrPass = new SSRPass({
            renderer,
            scene,
            camera,
            width: window.innerWidth,
            height: window.innerHeight,
            encoding: THREE.sRGBEncoding,
            isPerspectiveCamera: true,
            morphTargets: true,
        });
        ssrComposer.addPass(ssrPass);

        ssrPass.isDistanceAttenuation = true;

        if (reflectionDist)
            ssrPass.maxDistance = reflectionDist;
        else
            ssrPass.maxDistance = 0.01;

        ssrPass.isFresnel = true;

        if (opacity)
            ssrPass.opacity = opacity;
        else
            ssrPass.opacity = 0.2;


        ssrPass.infiniteThick = false;

        return ssrComposer;
        // ssrPass.groundReflector = groundReflector
    }
}

export { Reflections };