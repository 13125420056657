import { LegacyJSONLoader } from './LegacyJSONLoader.js';

class ThreejsExtension {
    constructor() {

    }

    parseGeometries(json, shapes) {
        var geometries = {};
        let geometryShapes;

        if (json !== undefined) {
            var bufferGeometryLoader = new THREE.BufferGeometryLoader();

            for (let i = 0, l = json.length; i < l; i++) {
                let geometry;
                var data = json[i];

                switch (data.type) {
                    case 'PlaneGeometry':
                    case 'PlaneBufferGeometry':
                        geometry = new Geometries[data.type](data.width, data.height, data.widthSegments, data.heightSegments);
                        break;

                    case 'BoxGeometry':
                    case 'BoxBufferGeometry':
                        geometry = new Geometries[data.type](data.width, data.height, data.depth, data.widthSegments, data.heightSegments, data.depthSegments);
                        break;

                    case 'CircleGeometry':
                    case 'CircleBufferGeometry':
                        geometry = new Geometries[data.type](data.radius, data.segments, data.thetaStart, data.thetaLength);
                        break;

                    case 'CylinderGeometry':
                    case 'CylinderBufferGeometry':
                        geometry = new Geometries[data.type](data.radiusTop, data.radiusBottom, data.height, data.radialSegments, data.heightSegments, data.openEnded, data.thetaStart, data.thetaLength);
                        break;

                    case 'ConeGeometry':
                    case 'ConeBufferGeometry':
                        geometry = new Geometries[data.type](data.radius, data.height, data.radialSegments, data.heightSegments, data.openEnded, data.thetaStart, data.thetaLength);
                        break;

                    case 'SphereGeometry':
                    case 'SphereBufferGeometry':
                        geometry = new Geometries[data.type](data.radius, data.widthSegments, data.heightSegments, data.phiStart, data.phiLength, data.thetaStart, data.thetaLength);
                        break;

                    case 'DodecahedronGeometry':
                    case 'DodecahedronBufferGeometry':
                    case 'IcosahedronGeometry':
                    case 'IcosahedronBufferGeometry':
                    case 'OctahedronGeometry':
                    case 'OctahedronBufferGeometry':
                    case 'TetrahedronGeometry':
                    case 'TetrahedronBufferGeometry':
                        geometry = new Geometries[data.type](data.radius, data.detail);
                        break;

                    case 'RingGeometry':
                    case 'RingBufferGeometry':
                        geometry = new Geometries[data.type](data.innerRadius, data.outerRadius, data.thetaSegments, data.phiSegments, data.thetaStart, data.thetaLength);
                        break;

                    case 'TorusGeometry':
                    case 'TorusBufferGeometry':
                        geometry = new Geometries[data.type](data.radius, data.tube, data.radialSegments, data.tubularSegments, data.arc);
                        break;

                    case 'TorusKnotGeometry':
                    case 'TorusKnotBufferGeometry':
                        geometry = new Geometries[data.type](data.radius, data.tube, data.tubularSegments, data.radialSegments, data.p, data.q);
                        break;

                    case 'TubeGeometry':
                    case 'TubeBufferGeometry':
                        geometry = new Geometries[data.type](new Curves[data.path.type]().fromJSON(data.path), data.tubularSegments, data.radius, data.radialSegments, data.closed);
                        break;

                    case 'LatheGeometry':
                    case 'LatheBufferGeometry':
                        geometry = new Geometries[data.type](data.points, data.segments, data.phiStart, data.phiLength);
                        break;

                    case 'PolyhedronGeometry':
                    case 'PolyhedronBufferGeometry':
                        geometry = new Geometries[data.type](data.vertices, data.indices, data.radius, data.details);
                        break;

                    case 'ShapeGeometry':
                    case 'ShapeBufferGeometry':
                        geometryShapes = [];

                        for (let j = 0, jl = data.shapes.length; j < jl; j++) {
                            var shape = shapes[data.shapes[j]];
                            geometryShapes.push(shape);
                        }

                        geometry = new Geometries[data.type](geometryShapes, data.curveSegments);
                        break;

                    case 'ExtrudeGeometry':
                    case 'ExtrudeBufferGeometry':
                        geometryShapes = [];

                        for (let j = 0, jl = data.shapes.length; j < jl; j++) {
                            var shape = shapes[data.shapes[j]];
                            geometryShapes.push(shape);
                        }

                        var extrudePath = data.options.extrudePath;

                        if (extrudePath !== undefined) {
                            data.options.extrudePath = new Curves[extrudePath.type]().fromJSON(extrudePath);
                        }

                        geometry = new Geometries[data.type](geometryShapes, data.options);
                        break;

                    case 'BufferGeometry':
                    case 'InstancedBufferGeometry':
                        geometry = bufferGeometryLoader.parse(data);
                        break;

                    case 'Geometry':
                        var geometryLoader = new LegacyJSONLoader();
                        geometry = geometryLoader.parse(data, this.resourcePath).geometry;
                        if(parseInt(THREE.REVISION) > 111){
                            geometry = geometry.toBufferGeometry();
                        }else{
                            geometry = new THREE.BufferGeometry().fromGeometry(geometry);
                        }
                        
                        break;

                    default:
                        console.warn('THREE.ObjectLoader: Unsupported geometry type "' + data.type + '"');
                        continue;
                }

                geometry.uuid = data.uuid;
                if (data.name !== undefined) geometry.name = data.name;
                if (geometry.isBufferGeometry === true && data.userData !== undefined) geometry.userData = data.userData;
                geometries[data.uuid] = geometry;
            }
        }

        return geometries;
    }
}

export { ThreejsExtension }