import { HotspotManager } from './HotspotManager.js';

class Import {

  constructor() {

  }


  importNewCDSPfromJSONObject(jsonData) {
    Import.cdspData = jsonData;
    Import.oldcdspFile = false;
    var obj = jsonData.fileInfo.cad.models;
    if (obj != undefined) {
      cds.cadViewer.cadFilePath = obj[0].url;
      console.log(obj[0].url)
      if (obj[0].url) {
        cds.studio.jsonFile = null;
        if (cds.cadViewer.sceneObject) {
          cds.studio.dispose();
        }
        cds.studio.file.loadModel(obj[0].url);
      } else {
        console.log("URL not found");
      }
    }
  }


  // Function Written by Prashant
  importCDSP(url) {
    let newcdsp = 'http://protodev.product-config.net/temp/prince/samplemodels/test.cdsp';
    //let newcdsp = "http://protodev.product-config.net/temp/prince/samplemodels/anim240521.cdsp"
    //let newcdsp = 'http://protodev.product-config.net/temp/prince/samplemodels/anim_latest.cdsp'
    //let newcdsp = 'http://protodev.product-config.net/temp/prince/samplemodels/anim_animation.cdsp'
    let oldcdsp = 'http://protodev.product-config.net/temp/prince/samplemodels/oldcdsp.cdsp';
    this.getJSON(url,
      function (err, data) {
        if (err !== null) {
          alert('Something went wrong: ' + err);
        } else {
          //Code written for detecting IE browser for converting text into JSON object.
          if (cds.cadViewer.boolIE) {
            data = JSON.parse(data);
          }
          Import.cdspData = data;
          if (data.fileInfo != undefined) {
            console.log("New cdsp file");
            Import.oldcdspFile = false;
            var obj = data.fileInfo.cad.models;
            if (obj != undefined) {
              //Prashant code
              let temp_url =  obj[0].url;
              if (temp_url.includes("http")){
                cds.cadViewer.cadFilePath = temp_url;
                console.log(temp_url)
                if (temp_url) {
                  //cds.studio.jsonFile = null;
                  if (cds.cadViewer.sceneObject) {
                    cds.studio.dispose();
                  }
                  cds.cadViewer.loadScene(temp_url);
              } else {
                console.log("URL not found");
              }
              }else{ //Server URL //Prashant
                 //console.log("Server URL for S3 public server");
                 var the_arr = url.split('/');
                 the_arr.pop();
                 let newDir = the_arr.join("/") ;
                 let newURL = newDir + '/' + temp_url;
                 //console.log(newURL)
                if (cds.cadViewer.sceneObject) {
                  cds.studio.dispose();
                }
                cds.cadViewer.loadScene(newURL);
              }
            }
          } else {
            Import.oldcdspFile = true;
            console.log("Old cdsp file");
          }
        }
      });
    console.log("Function end");
  }

  importJSONintoStudio() {

    cds.cadViewer.studioUtils = new cds.cadViewer.StudioUtils(); //Written by Prashant
    let studioFeatures = cds.cadViewer.sceneObject.userData.studioFeatures; //Written by Prashant


    var data = Import.cdspData;
    if (data.features) {

      // This code is for Explosion
      if (Object.keys(data.features.explosions).length > 0) {
        var explosion = data.features.explosions[0];
        if (explosion != undefined) {
          cds.cadViewer.sceneObject.traverse(function (child) {
            if (explosion.data[child.userData.compId] !== undefined) {
              child.userData.ExplodedMatrix = explosion.data[child.userData.compId];
            } else if (child.type === "Object3D") {
              child.userData.ExplodedMatrix = undefined;
            }
          });
          cds.cadViewer.sceneObject.userData.hasExplosion = true;
        }
      }

      if(Object.keys(data.extras).length > 0){
        cds.cadViewer.extraProperties.shadow = data.extras.enableShadow;
        cds.cadViewer.extraProperties.cameraUpDown = data.extras.enableCameraUpDown;
        cds.cadViewer.extraProperties.rotation = data.extras.enableContinuousRotateOnLoad;
        cds.cadViewer.extraProperties.HotspotOverlayType = data.extras.hotspotOverlayDisplayType;
      }

      //This code is for Annotation
      if (Object.keys(data.features.annotations).length > 0) {
        var annotationData = data.features.annotations;
        //cds.cadViewer.studioUtils.applyAnnotations(annotationData)
        cds.cadViewer.sceneObject.traverse(function (child) {
          if (annotationData.hasOwnProperty(child.userData.compId)) {
            //cds.studio.annotation.assignAnnotation(child, annotationData[child.userData.compId]);
            //cds.studio.annotation.assignAnnotationFromNewCdspFile(child, annotationData[child.userData.compId]);
            cds.cadViewer.studioUtils.createAnnotationFromNewCDSPFile(child, annotationData[child.userData.compId]); //Written By Prashant Vyas

          }
        });

        cds.cadViewer.renderer.domElement.addEventListener("mousemove", this.highlightAnnotationDiv, false);

        cds.cadViewer.postModelTreeLoadCallback();

        cds.cadViewer.studioUtils.addCart();

        let e = document.getElementById("annot-icon");
        if (e) { e.style.display = "" }

        if (cds.cadViewer.studioUtils.annotationContainer.length > 0) {
          cds.cadViewer.sceneObject.userData.hasAnnotation = true;
        }
      }

      //This code is for 3D Hotspot
      if ( Object.keys(data.features.hotspots).length > 0 ) {
        var hotspotData = data.features.hotspots;
        //if (!cds.studio.annotation.hotspot) {
        //cds.studio.annotation.loadHotspot('images/pin.svg');
        //}
        //cds.studio.annotation.hotspot.scale.set(hotspotData.scaleValue,hotspotData.scaleValue, hotspotData.scaleValue);
        //cds.studio.annotation.addHotspotToScene(hotspotData);
        cds.cadViewer.sceneObject.userData.hasAnnotation = true;
        try {
          cds.cadViewer.hotspotManager = new HotspotManager();
          cds.cadViewer.studioUtils.addHotspot(hotspotData)
          let e = document.getElementById("sprite-icon")
          if (e) { e.style.display = "" }
        } catch (e) {
          console.log("Error: " + e);
        }

      }



      //This code is for Viewpoint
      if (Object.keys(data.features.views).length > 0) {
        //console.log(cds.studio.viewPoint);
        var viewData = data.features.views;
        for (var i = 1; i < viewData.length; i++) {
          let v_data = viewData[i];
          //cds.studio.viewPoint.viewPoints.push({ name: v_data.name, matrix: { elements : v_data.matrix }, target: v_data.target });
          //cds.studio.viewPoint.addToList( v_data.name);
          //cds.studio.viewPoint.selectDomElement.value =  v_data.name;
          //cds.studio.viewPoint.selectDomElement.options.selectedIndex = 0; 
        }
      }

       if (Object.keys(data.features.materials).length > 0) {// written by yash
          cds.cadViewer.addEnvMap('images/envMap/', 'jpg', [ "Cloud"]);
          cds.cadViewer.applyEnvironmentMap("Cloud", "Reflection");
      } 
      //This code is for load Material from material feature
      // if (data.features.materials) {
      //   var materialData = data.features.materials;
      //   if (materialData.mapping != undefined) {
      //     try {
      //       cds.cadViewer.sceneObject.traverse(function (child) {
      //         if (child.type === "Mesh") {
      //           // for (var i = 0; i < materialData.mapping.materialMapping.length; i++) {
      //           //   for (var j = 0; j < child.material.length; j++) {
      //           //     if (child.material[j].uuid == materialData.mapping.materialMapping[i].materialID) {
      //           //       let materialname = materialData.mapping.materialMapping[i].materialName;
      //           //       child.material[j].name = materialData.mapping.materialMapping[i].materialName;
      //           //       let material = child.material[j];
      //           //       var property = materialData.material[materialname];
      //           //       material.color = new THREE.Color(property.color);
      //           //       material.emissive = new THREE.Color(property.emissive);
      //           //       material.clearCoatRoughness = property.clearCoatRoughness;
      //           //       material.clearCoat = property.clearCoat;
      //           //       material.metalness = property.metalness;
      //           //       material.roughness = property.roughness;
      //           //       material.reflectivity = property.reflectivity;
      //           //       material.transparent = property.opacity !== 1 ? true : false;
      //           //       material.opacity = property.opacity;

      //           //       if (property.map) {
      //           //         let uuid = property.map;
      //           //         if (materialData.textures[uuid]) {
      //           //           //cds.studio.loadMapForOpenProject(material, materialData.textures[uuid]);
      //           //           let jsonTexture = materialData.textures[uuid];
      //           //           let texture = material;
      //           //           new THREE.TextureLoader().load(jsonTexture.name, function (texture) {
      //           //             material.map = texture;
      //           //             material.map.name = jsonTexture.name;
      //           //             material.map.wrapS = THREE.RepeatWrapping;
      //           //             material.map.wrapT = THREE.RepeatWrapping;
      //           //             material.map.offset = jsonTexture.offset;
      //           //             material.map.repeat = jsonTexture.repeat;
      //           //             material.map.center = jsonTexture.center;
      //           //             material.map.rotation = jsonTexture.rotation;
      //           //             material.needsUpdate = true;
      //           //           }, undefined, function (event) {
      //           //             alert("Failed to load texture from URL " + event.target.src);
      //           //           });
      //           //         }
      //           //       }

      //           //       if (property.bumpMap) {
      //           //         let uuid = property.bumpMap;
      //           //         if (materialData.textures[uuid]) {
      //           //           let jsonTexture = materialData.textures[uuid];
      //           //           let texture = material;
      //           //           //cds.studio.loadBumpMapForOpenProject(material, materialData.textures[uuid]);
      //           //           new THREE.TextureLoader().load(jsonTexture.name, function (texture) {
      //           //             material.bumpMap = texture;
      //           //             material.bumpMap.name = jsonTexture.name;
      //           //             material.bumpMap.wrapS = THREE.RepeatWrapping;
      //           //             material.bumpMap.wrapT = THREE.RepeatWrapping;
      //           //             material.bumpMap.offset = jsonTexture.offset;
      //           //             material.bumpMap.repeat = jsonTexture.repeat;
      //           //             material.bumpMap.center = jsonTexture.center;
      //           //             material.bumpMap.rotation = jsonTexture.rotation;
      //           //             material.needsUpdate = true;
      //           //           }, undefined, function (event) {
      //           //             alert("Failed to load texture from URL " + event.target.src);
      //           //           });
      //           //         }
      //           //       }

      //           //     }
      //           //   }

      //           // }
      //         }
      //       });
      //     } catch (e) { }
      //   }
      // }





      //This code is for Add Ligth
      if (Object.keys(data.features.lights).length > 0) {
        if (data.features.lights.length > 0) {
          let lightData = data.features.lights;
          cds.cadViewer.studioUtils.setLightsEnv();
          for (let i = 0; i < lightData.length; i++) {
            let data = lightData[i];
            if (data.type == "SpotLight") {
              cds.cadViewer.studioUtils.setSpotLight(data);
            }
            else if (data.type == "DirectionalLight") {
              cds.cadViewer.studioUtils.setDirectionalLight(data);
            }
            else if (data.type == "AmbientLight") {
              cds.cadViewer.studioUtils.setAmbientLight(data);
            } else if (data.type == "PointLight") {
              cds.cadViewer.studioUtils.setPointLight(data);
            } else if (data.type == "HemisphereLight") {
              cds.cadViewer.studioUtils.setHemisphereLight(data);
            }
            else {

            }
          }
          cds.cadViewer.studioUtils.hideAll();

        } else {
          console.log("No light data found");
        }

      }


      //This code is for load Animation
      if (Object.keys(data.features.animations).length > 0) {
        let animationData = data.features.animations;
        if (animationData != undefined) {
          let animationDataNew = animationData['0']
          if (animationDataNew != undefined) {
            let animation = animationDataNew.animation;
            var maxTime = 0;
            var scope = cds.cadViewer.studioUtils;
            scope.mixer = new THREE.AnimationMixer();
            if (animation) {
              cds.cadViewer.traverse(cds.cadViewer.sceneObject, function (child) {
                let cId = child.userData.compId;
                //console.log(cId);
                let animClipsByComp = animation[cId];
                if (animClipsByComp != undefined) {
                  let time = 0;
                  for (let i = 0; i < animClipsByComp.length; i++) {
                    let clipObj = animClipsByComp[i];
                    let animationAction = scope.mixer.clipAction(THREE.AnimationClip.parse(clipObj.clip), child);
                    animationAction.repetitions = clipObj.infinity === false ? 0 : Infinity;
                    animationAction.clampWhenFinished = true;

                    if (!scope.animationActionsByPart[cId]) scope.animationActionsByPart[cId] = [];
                    scope.animationActionsByPart[cId].push(animationAction);

                    time += clipObj.clip.duration;

                    if (maxTime < time && animationAction.repetitions !== Infinity) {
                      maxTime = time;
                      scope.lastAnimationAction = animationAction;
                    }

                    if (animationAction.repetitions === Infinity)
                      scope.inifinityAnimationExist = true;

                    if (clipObj.delay >= 0) {
                      scope.infinityAnimationDelay[clipObj.clip.name] = clipObj.delay;
                    }

                    let mClipName = cId + "." + i;
                    if (animation[mClipName]) {

                      if (!scope.m_animationActionsByPart[mClipName]) scope.m_animationActionsByPart[mClipName] = [];
                      let matMapped = {}

                      child.traverse(function (mesh) {
                        if (mesh.type === "Mesh") {
                          animation[mClipName].forEach(function (clipObj) {
                            if (Array.isArray(mesh.material)) {
                              for (let j = 0; j < mesh.material.length; j++) {
                                if (mesh.material[j].uuid === clipObj.materialuuid && !matMapped[clipObj.materialuuid]) {

                                  let animation_action = scope.mixer.clipAction(THREE.AnimationClip.parse(clipObj.clip), mesh.material[j]);
                                  animation_action.repetitions = animationAction.repetitions;
                                  scope.m_animationActionsByPart[mClipName].push(animation_action);

                                  // mesh.material[j].transparent = true;
                                  matMapped[clipObj.materialuuid] = true;
                                }
                              }

                              for (var j = 0; j < mesh.parent.children.length; ++j) {
                                if (mesh.parent.children[j].type == "Sprite") {
                                  let animation_action = scope.mixer.clipAction(THREE.AnimationClip.parse(clipObj.clip), mesh.parent.children[j].material);
                                  animation_action.repetitions = animationAction.repetitions;
                                  scope.m_animationActionsByPart[mClipName].push(animation_action);
                                  mesh.parent.children[j].material.transparent = true;
                                  matMapped[mesh.parent.children[j].material.uuid] = true;
                                }
                              }
                            } else {
                              if (mesh.material.uuid === clipObj.materialuuid) {
                                let animation_action = scope.mixer.clipAction(THREE.AnimationClip.parse(clipObj.clip), mesh.material);
                                animation_action.repetitions = animationAction.repetitions;
                                scope.m_animationActionsByPart[mClipName].push(animation_action);

                                mesh.material.transparent = true;
                              }
                            }

                          })
                        }
                      });
                    }
                  }

                  child.userData.initialQuat = child.quaternion.clone();

                }
              }, function () {
                if (animation["camera"] && animation["controls"]) {

                  let camClipsObj = animation["camera"];
                  let controlClipsObj = animation["controls"];

                  scope.animationActionsByPart["camera"] = [];
                  scope.animationActionsByPart["controls"] = [];

                  for (let i = 0; i < camClipsObj.length; i++) {
                    let animAction = scope.mixer.clipAction(THREE.AnimationClip.parse(camClipsObj[i].clip), cds.cadViewer.camera);
                    animAction.repetitions = camClipsObj[i].infinity === false ? 0 : Infinity;
                    animAction.clampWhenFinished = true;
                    if (camClipsObj[i].delay > 0) scope.infinityAnimationDelay[camClipsObj[i].clip.name] = camClipsObj[i].delay;
                    scope.animationActionsByPart["camera"].push(animAction);
                  }

                  for (let i = 0; i < controlClipsObj.length; i++) {
                    let animAction = scope.mixer.clipAction(THREE.AnimationClip.parse(controlClipsObj[i].clip), cds.cadViewer.controls);
                    animAction.repetitions = controlClipsObj[i].infinity === false ? 0 : Infinity;
                    animAction.clampWhenFinished = true;
                    if (controlClipsObj[i].delay > 0) scope.infinityAnimationDelay[controlClipsObj[i].clip.name] = controlClipsObj[i].delay;
                    scope.animationActionsByPart["controls"].push(animAction);
                  }
                }
              });


              //cds.cadViewer.onAnimationLoad()
              scope.mixer.addEventListener('finished', scope.onFinishAnimation);
              cds.cadViewer.sceneObject.userData.hasAnimation = true;
              let e = document.getElementById("anim-icon")
              if (e) { e.style.display = "" }
            }
          }


        }
      }

    }


  }

  //parseJSONtoCDSobject(data){

  //}

  loadViewPoint() {
    var data = Import.cdspData;

  }

  getJSON = function (url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    //xhr.withCredentials = true // Prince
    xhr.onload = function () {
      var status = xhr.status;
      if (status === 200) {
        callback(null, xhr.response);
      } else {
        callback(status, xhr.response);
      }
    };
    xhr.send(null);
  };





}

export { Import };
window.Import = Import;